
const caseManagementTimeOptions = [
  {
    label: '9:30-10:30',
    value: '09:30:00',
    duration: '60',
  },
  {
    label: '10:30-11:30',
    value: '10:30:00',
    duration: '60',
  },
  {
    label: '11:30-12:30',
    value: '11:30:00',
    duration: '60',
  },
  {
    label: '2:30-3:30',
    value: '14:30:00',
    duration: '60',
  },
  // {
  //   label: '23:59:59',
  //   value: '23:59:59',
  //   duration: '45',
  // },
];

export default caseManagementTimeOptions;